import React, { FormEvent, useState } from "react";
import Unit from "../../models/Unit";
import Receipt from "../../models/Receipt";
import PrintUnitDetails from "../utils/PrintUnitDetails";
import { useReactToPrint } from "react-to-print";
import PrintUnitDetailsWrapper from "../utils/PrintUnitDetailsWrapper";
import EditReceiptConfirmationModal from "./EditReceiptConfirmationModal";
import classes from "./UnitDetailsModal.module.css";

interface UnitDetailsModalProps {
  show: boolean;
  onHide: () => void;
  unit: Unit;
}

const UnitDetailsModal: React.FC<UnitDetailsModalProps> = ({
  show,
  onHide,
  unit: initialUnit,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedReceipt, setSelectedReceipt] = useState<Receipt | null>(null);
  const [selectReceiptIndex, setSelectReceiptIndex] = useState<number | null>(
    null
  );
  const [unit, setUnit] = useState(initialUnit);
  const [editReceipt, setEditReceipt] = useState<boolean>(false);
  const [editedReceiptNewAmount, setEditedReceiptNewAmount] =
    useState<number>(0);

  const itemsPerPage = 5;

  const receiptList = unit.receipts || [];

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  let currentReceipts: Receipt[] = [];

  if (unit.receipts) {
    currentReceipts = unit.receipts.slice(indexOfFirstItem, indexOfLastItem);
  }

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const printComponentRef = React.createRef<PrintUnitDetailsWrapper>();

  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current, // Pass the ref of the component to print
  });

  const handleEditReceiptClick = (receipt: Receipt, index: number) => {
    setSelectedReceipt(receipt);
    setSelectReceiptIndex(index);
  };

  const handleEditReceiptConfirmation = (
    data: Object,
    index: number | null
  ) => {
    console.log(data, index);

    if (index !== null) {
      // Create a copy of the unit object
      const updatedUnit = { ...unit };

      // Check if receipts array exists and is not undefined in updatedUnit
      if (updatedUnit.receipts && updatedUnit.receipts[index]) {
        // Modify the receipt at the specified index with the new data
        updatedUnit.receipts[index] = {
          ...updatedUnit.receipts[index],
          ...data, // Apply the new data
        };

        // Update the state with the modified unit object
        setUnit(updatedUnit);

        // Close the modal for editing the receipt
        setSelectedReceipt(null);
        setSelectReceiptIndex(null);
      }
    }
  };

  const handleEditedReciptUpdate = async (e: FormEvent) => {
    e.preventDefault();

    if (selectedReceipt && unit) {
      console.log("selectedRecipt: ", selectedReceipt);
      console.log("selectedReceiptIndex: ", selectReceiptIndex);
      console.log("unit: ", unit);

      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_IP}/units/${unit._id}/receipts/${selectReceiptIndex}`,
          {
            method: "PUT",
            body: JSON.stringify({
              ...selectedReceipt,
              amount: editedReceiptNewAmount,
            }),
            headers: {
              "Content-Type": "application/json",
              Authorization: localStorage.getItem("auth_token") as string,
            },
          }
        );

        // Log the status and the full response object
        console.log("Response status: ", response.status);
        console.log("Full response: ", response);

        if (!response.ok) {
          alert("Error updating receipt");
        }

        const responseData = await response.json();
        console.log("Response data: ", responseData);

        const updatedUnit = { ...unit };
        if (updatedUnit.receipts && selectReceiptIndex !== null) {
          updatedUnit.receipts[selectReceiptIndex] = {
            ...updatedUnit.receipts[selectReceiptIndex],
            amount: editedReceiptNewAmount, // Apply the new amount or other changes
          };
        }

        // Update the unit state with the modified receipt
        setUnit(updatedUnit);

        // Reset the editing state and close the edit form
        setEditReceipt(false);
        setSelectedReceipt(null);
        setSelectReceiptIndex(null);
      } catch (error) {
        console.error("Error updating receipt: ", error);
        alert("An error occurred while updating the receipt.");
      }
      // setEditReceipt(false);
    }
  };

  let unitType;

  switch (unit.type) {
    case "normal":
      unitType = "وحدة سكنية";
      break;
    case "commercial":
      unitType = "وحده تجاريه";
      break;
    case "special-commercial":
      unitType = "وحده تجاريه في دور سكني";
      break;
    default:
      unitType = "";
  }

  const totalPages = Math.ceil(receiptList.length / itemsPerPage);

  return (
    <div>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 1000,
          cursor: "pointer",
        }}
      />
      <div className="modal" tabIndex={-1} style={{ display: "block" }}>
        <div className="modal-dialog modal-dialog-centered modal-lg">
          {" "}
          {/* Add modal-lg class to make the modal larger */}
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">تفاصيل الوحدة</h5>
              <button
                type="button"
                className="btn-close"
                onClick={onHide}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {/* Display unit details here */}
              <p>رقم الوحدة: {unit.number}</p>
              <p>المساحة: {unit.area} م&sup2;</p>

              <p>اسم المالك: {unit.owner}</p>
              <p>
                المصروفات الشهرية:{" "}
                {unit.monthlyExpense
                  ? Math.round(unit?.monthlyExpense.valueOf())?.toString()
                  : ""}
              </p>
              <p>المبنى: {unit.building.name}</p>
              <p>الساكن الحالى: {unit.resident}</p>
              <p>نوع الوحدة: {unitType}</p>

              {editReceipt && (
                <div className="mb-2 mt-2 border border-info px-2 py-2">
                  <h6 className="mb-2">تعديل الايصال</h6>
                  <form
                    onSubmit={handleEditedReciptUpdate}
                    className="mb-2 mt-2 d-flex flex-column align-items-center justify-content-center gap-2"
                  >
                    <input
                      className="form-control"
                      type="text"
                      placeholder="المبلغ"
                      value={editedReceiptNewAmount}
                      onChange={(e) =>
                        setEditedReceiptNewAmount(Number(e.target.value))
                      }
                    />
                    <button className="btn btn-primary w-50" type="submit">
                      حفظ
                    </button>
                  </form>
                </div>
              )}

              <h5>الايصالات</h5>
              {unit.receipts ? (
                <>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>الشهر</th>
                        <th>السنة</th>
                        <th>المدفوعة</th>
                        <th>الوصف</th>
                        <th>المبلغ</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentReceipts.map(
                        (receipt: Receipt, index: number) => (
                          <tr key={index}>
                            <td>{receipt.month}</td>
                            <td>{receipt.year}</td>
                            <td>
                              {receipt.isPaid ? "نعم" : "لا"}
                              <button
                                onClick={() =>
                                  handleEditReceiptClick(receipt, index)
                                }
                                className="btn btn-success me-4"
                              >
                                Edit
                              </button>
                            </td>
                            <td>{receipt.description}</td>
                            <td>{receipt.amount.toFixed(2)}</td>
                            <td>
                              <button
                                className="btn btn-primary"
                                onClick={() => handlePrint()}
                              >
                                Print
                              </button>
                              {!receipt.isPaid && (
                                <button
                                  onClick={() => {
                                    setEditReceipt((prevState) => {
                                      if (prevState) {
                                        setSelectedReceipt(null);
                                        setSelectReceiptIndex(null);
                                      } else {
                                        setSelectedReceipt(receipt);
                                        setSelectReceiptIndex(index);
                                      }
                                      return !prevState; // Toggle editReceipt
                                    });
                                  }}
                                  className="btn btn-success me-1"
                                >
                                  Edit
                                </button>
                              )}
                            </td>
                            <div className={classes.printOnly}>
                              <PrintUnitDetailsWrapper
                                ref={printComponentRef}
                                receipt={receipt}
                                unit={unit}
                              />
                            </div>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>

                  {/* Pagination */}
                  <nav aria-label="Receipts Pagination">
                    <ul className="pagination">
                      {Array.from({ length: totalPages }).map((_, index) => (
                        <li
                          key={index}
                          className={`page-item ${
                            index + 1 === currentPage ? "active" : ""
                          }`}
                        >
                          <button
                            className="page-link"
                            onClick={() => handlePageChange(index + 1)}
                          >
                            {index + 1}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </nav>
                </>
              ) : (
                <p>لا توجد ايصالات متاحة.</p>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={onHide}
              >
                إغلاق
              </button>
            </div>
          </div>
        </div>
      </div>
      <EditReceiptConfirmationModal
        show={!!selectedReceipt && !editReceipt}
        onHide={() => {
          setSelectedReceipt(null);
          setSelectReceiptIndex(null);
        }}
        onConfirm={handleEditReceiptConfirmation}
        selectedReceipt={selectedReceipt}
        selectedReceiptIndex={selectReceiptIndex}
        unit={unit}
      />
    </div>
  );
};

export default UnitDetailsModal;
