import React, { useEffect, useState, FormEvent } from "react";
import classes from "./UnitForm.module.css";
import Unit from "../../models/Unit";
import Building from "../../models/Building";

interface UnitModalProps {
  isOpen: boolean;
  onClose: () => void;
  onUnitAdded: (newUnit: Unit) => void;
  onUnitUpdated: (unit: Unit) => void;
  buildings: Building[];
  selectedUnit: Unit | null;
}

type UnitType = {
  _id: string | null;
  type: string;
  number: string;
  owner: string;
  area: number;
  building: string;
  resident: string;
};

const UnitForm: React.FC<UnitModalProps> = ({
  isOpen,
  onClose,
  onUnitAdded,
  onUnitUpdated,
  buildings,
  selectedUnit,
}) => {
  const initialFormData: Unit = {
    type: "null",
    number: "",
    owner: "",
    area: 0,
    building: { name: "", _id: "null" }, // Use _id: "null" as default
    resident: "",
    monthlyExpense: undefined,
  };

  const [formData, setFormData] = useState<Unit>(initialFormData);
  const [loading, setLoading] = useState<boolean>(false);

  // When selectedUnit changes, update formData
  useEffect(() => {
    if (selectedUnit) {
      console.log("SELECTED UNIT: ", selectedUnit);
      setFormData(selectedUnit);
    } else {
      setFormData(initialFormData);
    }
  }, [selectedUnit]);

  if (!isOpen) {
    return null;
  }

  const submitHandler = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);

    const unit: UnitType = {
      _id: selectedUnit?._id || null,
      type: formData.type,
      number: formData.number,
      owner: formData.owner,
      area: formData.area,
      building: formData.building._id, // Use building._id instead of buildingId
      resident: formData.resident,
    };

    const method = selectedUnit ? "PUT" : "POST";
    const url = selectedUnit
      ? `${process.env.REACT_APP_BACKEND_IP}/units/${selectedUnit._id}`
      : `${process.env.REACT_APP_BACKEND_IP}/units`;

    try {
      const response = await fetch(url, {
        method: method,
        headers: {
          "content-type": "application/json",
          Authorization: localStorage.getItem("auth_token") as string,
        },
        body: JSON.stringify(unit),
      });

      const responseData = await response.json();
      if (selectedUnit) {
        onUnitUpdated(responseData);
      } else {
        onUnitAdded(responseData);
        setFormData(initialFormData);
      }
    } catch (error) {
      console.error("Error processing unit:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={classes.Unit__Form}>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 1000,
          cursor: "pointer",
        }}
      />
      <div className="modal" tabIndex={-1} style={{ display: "block" }}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                onClick={onClose}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={submitHandler}>
                <h3>{selectedUnit ? "تعديل الوحدة" : "اضافة وحدة"}</h3>

                <div className="row">
                  <div className="col-6">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="رقم الوحدة"
                      value={formData.number}
                      onChange={(e) =>
                        setFormData({ ...formData, number: e.target.value })
                      }
                    />
                  </div>
                  <div className="col-6">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="اسم المالك"
                      value={formData.owner}
                      onChange={(e) =>
                        setFormData({ ...formData, owner: e.target.value })
                      }
                    />
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col-6">
                    <select
                      className="form-select"
                      value={formData.type}
                      onChange={(e) =>
                        setFormData({ ...formData, type: e.target.value })
                      }
                    >
                      <option value="null">اختر نوع الوحدة</option>
                      <option value="normal">وحدة سكنية</option>
                      <option value="commercial">وحدة تجارية</option>
                      <option value="special-commercial">
                        وحدة تجارية فى دور سكنى
                      </option>
                    </select>
                  </div>
                  <div className="col-6">
                    <select
                      value={formData.building._id || "null"}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          building: {
                            ...formData.building,
                            _id: e.target.value,
                          },
                        })
                      }
                      className="form-select"
                    >
                      <option value="null">اختر البناية</option>
                      {buildings.map((b) => (
                        <option key={b._id} value={b._id}>
                          {b.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col-6">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="المساحة"
                      value={formData.area !== undefined ? formData.area : ""}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          area: parseInt(e.target.value),
                        })
                      }
                    />
                  </div>
                  <div className="col-6">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="الساكن الحالى"
                      value={formData.resident}
                      onChange={(e) =>
                        setFormData({ ...formData, resident: e.target.value })
                      }
                    />
                  </div>
                </div>

                <button disabled={loading} className="btn btn-primary mt-4">
                  حفظ
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnitForm;
