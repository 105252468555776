import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Building from "../../models/Building";
import classes from "./BuildingsAccordion.module.css";
import UnitCard from "../cards/UnitCard";
import PrintBuildingReceiptsWrapper from "../utils/PrintBuildingReceiptsWrapper";
import BuildingReceipt from "../../models/BuildingReceipt";
import { useReactToPrint } from "react-to-print";
import Unit from "../../models/Unit";
import SelectMonth from "../modals/SelectMonth";

interface AccordionItemProps {
  building: Building;
  onEditBuilding: (building: Building) => void;
  onDeleteBuilding: (buildingId: string) => void;
  onUpdateBuilding: (building: Building) => void;
}

const AccordionItem: React.FC<AccordionItemProps> = ({
  building,
  onEditBuilding,
  onDeleteBuilding,
  onUpdateBuilding,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [buildingReceipts, setBuildingReceipts] = useState<BuildingReceipt[]>(
    []
  );
  const [showPrintModal, setShowPrintModal] = useState(false);
  const [showSummaryModal, setShowSummaryModal] = useState<boolean>(false);
  const [selectedMonth, setSelectedMonth] = useState<string>("all");
  const [showWarningMessage, setShowWarningMessage] = useState(false);

  useEffect(() => {
    if (buildingReceipts.length > 0) handlePrint();
  }, [buildingReceipts]);

  const navigate = useNavigate();
  const printComponentRef = React.createRef<PrintBuildingReceiptsWrapper>();

  const handleEditButtonClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onEditBuilding(building);
  };

  const handleDeleteButtonClick = async (e: React.MouseEvent) => {
    e.stopPropagation();

    if (window.confirm("هل تريد مسح هذه المبنى؟")) {
      if (building._id) {
        onDeleteBuilding(building._id); // Invoke the onDeletePlaza function
      } else {
        console.error("Plaza ID is undefined");
      }
    } else {
      return false;
    }
  };

  const handleAccordionItemClick = () => {
    setIsActive((prevIsActive) => !prevIsActive);
  };

  const handleShowMoreClick = () => {
    navigate(`/building-units/${building._id}#units-section`); // Navigate to /building-units route with anchor
  };

  const handlePrintReceipts = async (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowPrintModal(true);
  };

  const filterPrinting = async () => {
    try {
      // Fetch the building receipts data

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_IP}/buildings/${building._id}/receipts`,
        {
          headers: {
            Authorization: localStorage.getItem("auth_token") as string,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      let receiptsToBePrinted;

      if (selectedMonth === "all") {
        receiptsToBePrinted = data;
      } else {
        receiptsToBePrinted = data.filter((receipt: any) => {
          return receipt.receipt.month == selectedMonth;
        });
      }

      if (receiptsToBePrinted.length > 0) {
        setBuildingReceipts(receiptsToBePrinted);
        handlePrint();
        setShowPrintModal(false);
        setShowWarningMessage(false);
      } else {
        setShowWarningMessage(true);
      }
    } catch (error) {
      console.error("Error fetching receipts:", error);
    }
  };

  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current, // Pass the ref of the component to print
  });

  const generateReceipts = async (e: React.MouseEvent) => {
    e.stopPropagation();
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_IP}/buildings/${building._id}/generate-monthly-receipts`,
      {
        method: "POST",
        body: JSON.stringify({}),
        headers: {
          Authorization: localStorage.getItem("auth_token") as string,
        },
      }
    );

    if (!response.ok) {
      throw new Error("FFFFFFFFFFFFFFF");
    }

    const responseData = await response.json();

    console.log(responseData);
  };

  const handleDeleteUnit = async (unit: Unit) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_IP}/units/${unit._id}`,
        {
          method: "DELETE",
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete unit"); // Handle non-successful response
      }

      const updatedBuilding = {
        ...building,
        units: building.units.filter((u) => u._id !== unit._id),
      };

      onUpdateBuilding(updatedBuilding);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={`accordion-item ${isActive ? classes.Active : ""}`}>
      <h2 className="accordion-header" id={building._id}>
        <a
          className={`d-flex justify-content-between accordion-button ${
            isActive ? "collapsed" : ""
          }`}
          type="button"
          onClick={handleAccordionItemClick}
        >
          <span>{building.name}</span>

          <button
            className="btn btn-primary me-4"
            onClick={handleEditButtonClick}
          >
            Edit
          </button>
          <button
            onClick={handleDeleteButtonClick}
            className="btn btn-danger me-4"
          >
            Delete
          </button>
          <button
            onClick={handlePrintReceipts}
            className="btn btn-success me-4 "
          >
            Print Receipts
          </button>
          <button onClick={generateReceipts} className="btn btn-warning me-4 ">
            Generate Receipts
          </button>
          <button
            onClick={(e) => {
              e.stopPropagation();
              setShowSummaryModal(true);
            }}
            className="btn btn-primary me-4"
            style={{ backgroundColor: "purple", borderColor: "purple" }}
          >
            Export
          </button>
        </a>
        {/* START MODAL */}
        <div
          className={`modal fade ${showPrintModal ? "show" : ""}`}
          id="printReceiptsModal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="printReceiptsModalLabel"
          aria-hidden={!showPrintModal}
          style={{ display: showPrintModal ? "block" : "none" }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="printReceiptsModalLabel">
                  Print Receipts
                </h5>
                <button
                  type="button"
                  className="close"
                  onClick={() => setShowPrintModal(false)} // Close the modal
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                {/* Select dropdown for month selection */}
                <div className="form-group">
                  <label htmlFor="selectMonth" style={{ marginBottom: "4px" }}>
                    الشهر:
                  </label>
                  <select
                    id="selectMonth"
                    className="form-control"
                    value={selectedMonth}
                    onChange={(e) => {
                      setSelectedMonth(e.target.value);
                      setShowWarningMessage(false);
                    }}
                  >
                    <option value="all">جميع الاشهر</option>
                    <option value="1">January</option>
                    <option value="2">February</option>
                    <option value="3">March</option>
                    <option value="4">ِApril</option>
                    <option value="5">May</option>
                    <option value="6">June</option>
                    <option value="7">July</option>
                    <option value="8">August</option>
                    <option value="9">September</option>
                    <option value="10">October</option>
                    <option value="11">November</option>
                    <option value="12">December</option>
                  </select>
                </div>
                {showWarningMessage && "هذا الشهر لا يحتوى على اى ايصالات"}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setShowPrintModal(false)} // Close the modal
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    // Close the modal
                    filterPrinting(); // Print the content
                  }}
                >
                  Print
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* END MODAL */}

        {/* Start Summary Modal */}

        <SelectMonth
          building={building}
          isOpen={showSummaryModal}
          onClose={() => setShowSummaryModal(false)}
        />

        {/* End Summary Modal */}
      </h2>
      <div
        className={`accordion-collapse collapse ${isActive ? "show" : ""}`}
        id={`building-collapse-${building._id}`}
        aria-labelledby={building._id}
        data-bs-parent="#buildings-accordion"
      >
        <div className="accordion-body">
          {building.units?.length > 0 ? (
            <>
              <div className="row">
                {building.units.slice(0, 3).map((unit) => (
                  <li
                    className="list-group-item col-3 me-4 mb-2"
                    key={unit._id}
                  >
                    <UnitCard unit={unit} onDeleteUnit={handleDeleteUnit} />
                  </li>
                ))}
              </div>
              <button
                onClick={handleShowMoreClick}
                className="btn btn-success d-block mx-auto mt-2"
              >
                Show More
              </button>
            </>
          ) : (
            <div className="alert alert-info" role="alert">
              لا يوجد وحدات مضافة للبناية فى الوقت الحالى
            </div>
          )}
        </div>
      </div>
      <div className={classes.printOnly}>
        <PrintBuildingReceiptsWrapper
          ref={printComponentRef}
          buildingReceipts={buildingReceipts}
          building={building}
        />
      </div>
    </div>
  );
};

interface BuildingsAccordionProps {
  buildings: Building[];
  onEditBuilding: (building: Building) => void;
  onDeleteBuilding: (plazaId: string) => void;
  onUpdateBuilding: (Building: Building) => void;
}

const BuildingsAccordion: React.FC<BuildingsAccordionProps> = ({
  buildings,
  onEditBuilding,
  onDeleteBuilding,
  onUpdateBuilding,
}) => {
  return (
    <div>
      <div
        className={`accordion ${classes.Building__Accordion}`}
        id="buildings-accordion"
      >
        {buildings.map((building) => (
          <AccordionItem
            onEditBuilding={onEditBuilding}
            key={building._id}
            building={building}
            onDeleteBuilding={onDeleteBuilding}
            onUpdateBuilding={onUpdateBuilding}
          />
        ))}
      </div>
    </div>
  );
};

export default BuildingsAccordion;
